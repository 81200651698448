import React, { FC } from 'react';
import { HeroTextBannerType } from '../../../models/hero-text-banner-type';

const HeroTextBanner: FC<HeroTextBannerType> = ({
  description,
  headingSize,
  headline,
  subHeading,
}: HeroTextBannerType) => {
  const headlineComponent =
    headingSize === 'h1' ? (
      <h1 className="page-heading-one">{headline}</h1>
    ) : (
      <h2 className="page-heading-two">{headline}</h2>
    );

  return (
    <div className="py-component-spacing-mobile lg:py-component-spacing mx-auto text-center w-full xl:w-8/12">
      {subHeading && <h5 className="pb-4 page-heading-five">{subHeading}</h5>}

      {headlineComponent}

      {description && <div className="pt-4 text-grey-600">{description}</div>}
    </div>
  );
};

export default HeroTextBanner;
